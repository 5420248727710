import Page from "flarum/components/Page";

export default class BadgesOverviewPage extends Page {
  oninit(vnode) {
    super.oninit(vnode);
  }

  view() {
    return <p>Badge page</p>;
  }
}
